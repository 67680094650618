import { Route, Routes } from "react-router-dom";
import "swiper/css";
import "swiper/css/autoplay";
import "swiper/css/pagination";
import About from "./About";
import Contact from "./Contact";
import "./Layout.css";
import Partners from "./Partners";
import Service from "./Service";
import Team from "./Team";
import TeamMember from "./TeamMember";
import Home from "./home/Home";

function Layout() {
  return (
    <div className="mx-auto px-0 px-md-2 layout " >
      <div className="row mt-2">
        <div>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about-us" element={<About />} />
            <Route path="/team" element={<Team />} />
            <Route path="/team/:member" element={<TeamMember />} />
            <Route path="/service" element={<Service />} />
            <Route path="/contact" element={<Contact />} />
          </Routes>
          <Partners />
        </div>
      </div>
    </div>
  );
}

export default Layout;
