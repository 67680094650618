import React from "react";
import { Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { partnerLogos } from "../../utils/database";
import "./Partners.css";

const Partners = () => {
  return (
    <div className="partners mt-4 pt-5">
      <h2 className="main-color my-5 pt-5">Our Partners</h2>
      <Swiper
        className="partner-coursel"
        modules={[Autoplay]}
        spaceBetween={10}
        slidesPerView={6}
        // speed={3000}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
          waitForTransition: false,
        }}
        scrollbar={{ draggable: true }}
        loop={true}
      >
        {partnerLogos.map(({ id, image, link }) => (
          <SwiperSlide className="" key={id}>
            <a className="partner-link" target="_blank" href={link}>
              <img className="img-fluid" src={image} alt="slide image" />
            </a>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default Partners;
