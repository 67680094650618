import React from "react";
import {
  AiOutlineInstagram,
  AiOutlineMail
} from "react-icons/ai";
import { FaTiktok } from "react-icons/fa";
import { useParams } from "react-router-dom";
import { teamMemberInfo } from "../../utils/database";
import "./TeamMember.css";

const TeamMember = () => {
  const { member } = useParams();
  const info = teamMemberInfo[member];
  const { fullName, email, name, image, job, about, mainText, informations } =
    info;
  return (
    <div className="member">
      <div className="bg-light member-header py-4">
        <div className="member-short-info ms-2 p-2 ps-4 ps-xl-2 justify-content-center justify-content-sm-start">
          <div className="member__img-container">
            <img className="member-img p-0 me-2" src={image} alt="" />
          </div>
          <div className="member__info text-start member-title w-100 px-3 ms-3">
            <div>
              <h1 className="pt-3">{fullName}</h1>
              <p>{job}</p>
            </div>
            {/* <ul className="text-start d-flex justify-content-start list-unstyled">
              <li>
                <a
                  className="main-link"
                  target="_blank"
                  href="mail:example@gmail.com"
                >
                  <AiOutlineMail size={20} className="me-1" />
                  <span>{email}</span>
                </a>
              </li>
            </ul> */}
            <p>{mainText}</p>
          </div>
        </div>
      </div>
      <div className="about-member">
        <div className="col-lg-9 col-12  p-3 mt-5">
          <div className="border-dark border-top">
            <h6 className="text-uppercase text-start fw-bold py-4">
              About {name}
            </h6>
            {informations.map(({ id, type, text, list }) => {
              if (type === "text") {
                return (
                  <p className="text-justify" key={id}>
                    {text}
                  </p>
                );
              } else if (type === "list") {
                return (
                  <div key={id}>
                    <p className="text-start">{text}</p>
                    <ul className="text-start" key={id}>
                      {list.map((title) => {
                        return <li key={title}>{title}</li>;
                      })}
                    </ul>
                  </div>
                );
              }
            })}
            <p className="text-justify">{about}</p>
          </div>
        </div>
        <div className="col-lg-3 col-12 p-3 mt-5">
          <div className="border-dark border-top text-start">
            <h6 className="text-uppercase fw-bold py-4">Contact</h6>
            <ul className="text-start d-flex flex-column  justify-content-start list-unstyled">
              <li className="mb-2">
                <a
                  className="main-link d-flex align-items-center"
                  target="_blank"
                  href="mail:example@gmail.com"
                >
                  <AiOutlineMail size={20} className="me-1" />
                  <span>{email}</span>
                </a>
              </li>
              <li className="mb-2">
                <a
                  className="main-link d-flex align-items-center"
                  target="_blank"
                  href="https://tiktok.com/bilimli_adim"
                >
                  <FaTiktok size={20} className="me-1" />
                  <span>bilimli_adim</span>
                </a>
              </li>
              <li className="mb-2">
                <a
                  className="main-link d-flex align-items-center"
                  target="_blank"
                  href="https://instagram.com/bilimli_adim"
                >
                  <AiOutlineInstagram size={20} className="me-1" />
                  <span>bilimli_adim</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TeamMember;
