import emailjs from "@emailjs/browser";
import { Col, Row, message } from "antd";
import React, { useRef, useState } from "react";
import messageImg from "../../images/message.jpg";

const { REACT_APP_PRIVATE_KEY, REACT_APP_SERVICE_ID, REACT_APP_TEMPLATE_ID } =
  process.env;

function Contact() {
  const form = useRef();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [textMessage, setTextMessage] = useState("");

  const sendEmail = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        REACT_APP_SERVICE_ID,
        REACT_APP_TEMPLATE_ID,
        form.current,
        REACT_APP_PRIVATE_KEY
      )
      .then(
        (result) => {
          console.log(result.text);
          setEmail("");
          setName("");
          setTextMessage("");
          message.success({
            content: "Message send succcessfuly",
          });
        },
        (error) => {
          console.log(error.text);
          message.error({
            content:
              "There is an error while sending mail please check your internet connection",
          });
        }
      );
  };

  return (
    <div>
      <Row className="mt-3">
        <Col
          className="d-flex justify-content-center align-items-center"
          span={24}
          md={{
            span: 12,
          }}
        >
          <img className="img-fluid" src={messageImg} alt="message" />
        </Col>
        <Col
          className="d-flex justify-content-center align-items-center px-md-4 px-1 "
          span={24}
          md={{
            span: 12,
          }}
        >
          <form onSubmit={sendEmail} ref={form} className="w-100">
            <h1 className="title text-center my-4">Talk to Us</h1>
            <div className="form-group position-relative mb-3">
              <label htmlFor="formName" className="d-block">
                <i className="icon" data-feather="user"></i>
              </label>
              <input
                value={name}
                onChange={({ target }) => setName(target.value)}
                name="from_name"
                type="text"
                id="formName"
                className="form-control form-control-lg thick shadow-none"
                placeholder="Name"
              />
            </div>

            <div className="form-group position-relative mb-3">
              <label htmlFor="formEmail" className="d-block">
                <i className="icon" data-feather="mail"></i>
              </label>
              <input
                value={email}
                onChange={({ target }) => setEmail(target.value)}
                type="email"
                id="formEmail"
                name="user_email"
                className="form-control form-control-lg thick shadow-none"
                placeholder="E-mail"
              />
            </div>

            <div className="form-group message mb-3">
              <textarea
                value={textMessage}
                onChange={({ target }) => setTextMessage(target.value)}
                name="message"
                id="formMessage"
                className="form-control form-control-lg shadow-none"
                rows="7"
                placeholder="Message"
              ></textarea>
            </div>

            <div className="text-center mb-3">
              <button
                type="submit"
                className="main-btn rounded py-1"
                tabIndex="-1"
              >
                Send
              </button>
            </div>
          </form>
        </Col>
      </Row>
    </div>
  );
}

export default Contact;
