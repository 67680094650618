import { Drawer } from "antd";
import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import logo from "../images/logo.png";

function MyDrower({ showDrawer, onClose, open }) {
  const navLinks = [
    { link: "/", title: "Home" },
    { link: "/about-us", title: "About" },
    { link: "/team", title: "Team" },
    { link: "/service", title: "Services" },
    { link: "/contact", title: "Contact" },
  ];

  const [active, setActive] = useState("/");
  const { pathname } = useLocation();

  useEffect(() => {
    setActive(pathname);
  }, [pathname]);

  return (
    <>
      <Drawer
        // title="BILIMLI ADIM"
        title={
          <img
            src={logo}
            style={{
              maxHeight: 25,
              position: "absolute",
              left: "50%",
              // top: '50%',
              transform: "translate(-35%, -50%)",
            }}
            className="img-fluid "
          />
        }
        placement="left"
        width={250}
        onClose={onClose}
        open={open}
        // closeIcon={<img src={logo} className="img-fluid"/>}
      >
        <ul className="d-flex list-unstyled flex-column m-1 text-center">
          {navLinks.map(({ link, title }) => {
            return (
              <li key={link} className="m-1 w-100">
                <Link
                  onClick={onClose}
                  className={`nav_link mx-1 px-3 py-2  text-decoration-none d-block ${
                    active === link ? "bg-main" : ""
                  }`}
                  to={link}
                >
                  {title}
                </Link>
              </li>
            );
          })}
        </ul>
      </Drawer>
    </>
  );
}

export default MyDrower;
