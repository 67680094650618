import { Col, Row } from "antd";
import React from "react";
import "swiper/css";
import "swiper/css/autoplay";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "./Home.css";
// import homeImage from '../../../images/university.png'
import { Autoplay, Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import home1 from "../../../images/Home/home1.jpg";
import home2 from "../../../images/Home/home2.jpg";
import home3 from "../../../images/Home/home3.jpg";
import home4 from "../../../images/Home/home4.jpg";
import building from "../../../images/building.jpg";
import { slideImages } from "../../../utils/database";

function Home() {
  return (
    <div className="home ">
      <Swiper
        modules={[Pagination, Autoplay, Navigation]}
        spaceBetween={5}
        slidesPerView={1}
        autoplay={{
          disableOnInteraction: false,
        }}
        pagination={{ clickable: true }}
        scrollbar={{ draggable: true }}
        loop={true}
        navigation
        // style={{ height: "480px" }}
        // style={{ height: 'auto', width: '100%' }}
        // className="zoom-container"
      >
        {slideImages.map(({ id, image, link, element }) => {
          return (
            <SwiperSlide key={id}>
              {/* <Link to={link}> */}
              {element}
              {/* </Link> */}
            </SwiperSlide>
          );
        })}
      </Swiper>

      <Row className="my-5 py-5">
        <Col
          className="d-flex flex-column justify-content-center main-color"
          sm={{
            span: 12,
          }}
          span={24}
        >
          <h1 className="fw-bold">BILIMLI ADIM</h1>
          <h5 className="primary-color pb-3">HIGHER EDUCATION CONSULTING</h5>
        </Col>
        <Col
          sm={{
            span: 12,
          }}
          span={24}
        >
          <img className="img-fluid" src={building} alt="home image" />
        </Col>
      </Row>
      <Row className="my-sm-5 py-5">
        <Col
          className="d-flex flex-column justify-content-start px-3 "
          md={{
            span: 8,
          }}
          span={24}
        >
          <img className="img-fluid" src={home1} alt="" />
          <p className="text-justify py-5">
            Recently, the state has been providing significant support to
            education in order to provide equal access to education services for
            all segments of the population. One of the criteria for
            accessibility is sufficient awareness of the population about the
            possibilities of obtaining education. The Ministry of Education of
            Turkmenistan is the central executive body that oversees and
            controls the education management system and educational
            institutions that implement a unified state policy in the field of
            education and youth policy.
          </p>
        </Col>
        <Col
          className="d-flex flex-column justify-content-start px-3 "
          md={{
            span: 8,
          }}
          span={24}
        >
          <img className="img-fluid" src={home2} alt="" />

          <p className="text-justify py-5">
            The Department of Higher and Secondary Vocational Education, being a
            structural subdivision of the Ministry of Education of Turkmenistan,
            implements the policy of the President of Turkmenistan regarding the
            system of higher and secondary vocational education, is guided in
            its work by the Constitution of Turkmenistan, the Law of
            Turkmenistan “On Education”, other laws of Turkmenistan, Decrees of
            the President of Turkmenistan, Resolutions Mejlis of Turkmenistan,
            Resolutions of the Cabinet of Ministers of Turkmenistan.
          </p>
        </Col>
        <Col
          className="d-flex flex-column justify-content-start px-3 "
          md={{
            span: 8,
          }}
          span={24}
        >
          <img className="img-fluid" src={home3} alt="" />
          <p className="text-justify py-5">
            The initiator of the project plans to organize a consulting company
            in Balkanabat, the main activity of which will be the provision of
            educational consulting services. The mission of the company will be
            to provide personalized consulting services in the field of
            education, also to engage exclusively in the field of consulting and
            marketing of international educational institutions of various
            industries around the world for study candidates from Turkmenistan.
          </p>
        </Col>
      </Row>
      <Row className="my-sm-5 py-sm-5 my-0 py-0">
        <Col
          className="d-flex flex-column justify-content-center"
          md={{
            span: 12,
          }}
          span={24}
        >
          <img style={{}} src={home4} alt="" />
        </Col>
        <Col
          md={{
            span: 12,
          }}
          span={24}
          className="p-2 "
        >
          <p className="fw-bold">
            The following points demonstrate a brief overview of the
            programs and plans of the company:
          </p>
          <ul className="list-style-none text-justify">
            <li>
              An in-depth analysis of the information flow about international
              educational institutions of various levels around the world;
            </li>
            <li>
              Prepare an extensive data archive of vacancies and educational
              opportunities of educational institutions for our clients
              (candidates);
            </li>
            <li>
              Create and maintain a trusting, professional relationship with
              international educational institutions, universities, colleges and
              school programs;
            </li>
            <li>
              Advise and assist with the documents of candidates for a study
              visa and for an educational institution;
            </li>
            <li>
              Correctly apply for study scholarships and international cash
              grants on behalf of the candidate;
            </li>
            <li>
              Selection and preparation of candidates for an educational
              institution by the method of additional test programs and through
              interviews;
            </li>
            <li>
              A professional approach to meeting the needs of society in
              specialists and highly qualified scientific and pedagogical
              personnel;
            </li>
            <li>
              Helping educational institutions develop long-term strategic plans
              in line with their mission, vision and market requirements;
            </li>
            <li>
              Establish business relations with international partners working
              in this field to exchange information;
            </li>
            <li>
              Collaboration with educational institutions to create and improve
              curricula in line with industry standards, promote active learning
              and integrate new trends.
            </li>
          </ul>
        </Col>
      </Row>
      <p className="text-justify">
        As the Turkmenistan’s largest provider of higher education professional
        services, we offer the fresh perspective and unique skills needed to
        address complex challenges and explore promising opportunities. We bring
        big ideas and practical solutions to advance teaching, learning,
        research, and community service. On every step of your transformation
        journey, we’re here to serve as collaborative partners on your path
        forward. Our mission is to empower individuals and organizations in
        education by providing high-quality advisory services and innovative
        solutions that drive growth, increase productivity and drive positive
        change.
      </p>
    </div>
  );
}

export default Home;
