import image1 from "../images/slides/slide1.jpg";
import image2 from "../images/slides/slide2.jpg";
import image3 from "../images/slides/slide3.jpg";

import partner1 from "../images/partners/1.jpg";
import partner2 from "../images/partners/2.jpg";
import partner3 from "../images/partners/3.jpg";
import partner4 from "../images/partners/4.jpg";
import partner5 from "../images/partners/5.jpg";
import partner6 from "../images/partners/6.jpg";
import partner7 from "../images/partners/7.jpg";
import partner8 from "../images/partners/8.png";

import team1 from "../images/team/Abdylla.jpg";
import team5 from "../images/team/Ahmet.jpg";
import team6 from "../images/team/Ahmet2.jpg";
import team4 from "../images/team/Dayanc.JPG";
import team2 from "../images/team/Gulnara.jpg";
import team3 from "../images/team/Rejep.JPG";

import testimonial1 from "../images/testimonials/Testimonial1.jpg";
import testimonial2 from "../images/testimonials/Testimonial2.jpg";
import testimonial3 from "../images/testimonials/Testimonial3.jpg";

export const slideImages = [
  {
    id: "d2fj3hg9",
    image: image1,
    link: "/team",
    element: <img className="img-fluid" src={image1} alt="slide image" />,
  },
  {
    id: "39fjf93",
    image: image2,
    link: "/service",
    element: <img className="img-fluid" src={image2} alt="slide image" />,
  },
  {
    id: "fn8h9f",
    image: image3,
    link: "/about",
    element: (
      <div className="slider-container">
        <img className="img-fluid slider-img" src={image3} alt="slide image" />
      </div>
    ),
  },
];

export const partnerLogos = [
  {
    id: "partner1",
    image: partner1,
    link: "https://msmacademy.eu",
  },
  {
    id: "partner2",
    image: partner2,
    link: "https://www.emu.edu.tr",
  },
  {
    id: "partner3",
    image: partner3,
    link: "https://en.grsu.by",
  },
  {
    id: "partner4",
    image: partner4,
    link: "https://tsi.lv",
  },
  {
    id: "partner5",
    image: partner5,
    link: "https://abroadz.com",
  },
  {
    id: "partner6",
    image: partner6,
    link: "https://uns.ac.id",
  },
  {
    id: "partner7",
    image: partner7,
    link: "https://www.tyuiu.ru",
  },
  {
    id: "partner8",
    image: partner8,
    link: "https://jankoyer.com.tm",
  },
];

export const teamData = [
  {
    id: "abdylla-haljanov",
    fullName: "Abdylla Haljanov",
    name: "Abdylla",
    image: team1,
    job: "Managing Director",
    shortDescription:
      "Managing Director responsible for the overall strategy and success of the team.",
  },
  {
    id: "gulnara-hanova",
    fullName: "Gulnara Hanova",
    name: "Gulnara",
    image: team2,
    job: "Senior Consultant",
    shortDescription:
      "Highly motivated and results-oriented consultant, always looking for ways to exceed clients' expectations.",
  },

  {
    id: "dayanch-gurbangeldiyev",
    fullName: "Dayanch Gurbangeldiyev",
    name: "Dayanch",
    image: team4,
    job: "Senior Consultant",
    shortDescription:
      "Senior Consultant with over 7 years of experience in the industry.",
  },
  {
    id: "ahmet-hojamberdiyev",
    fullName: "Ahmet Hojamberdiyev",
    name: "Ahmet",
    image: team5,
    job: "Senior Consultant",
    shortDescription:
      "Senior Consultant with over 6 years of experience in the industry.",
  },
  {
    id: "ahmet-tashliyev",
    fullName: "Ahmet Tashliyev",
    name: "Ahmet",
    image: team6,
    job: "Senior Consultant",
    shortDescription:
      "Senior Consultant with over 5 years of experience in the industry.",
  },
  {
    id: "rejepguly-hallyguliyev",
    fullName: "Rejepguly Hallyguliyev",
    name: "Rejepguly",
    image: team3,
    job: "Marketing Manager",
    shortDescription:
      "Marketing Manager responsible for developing and executing the team's marketing strategy.",
  },
];

export const teamMemberInfo = {
  "abdylla-haljanov": {
    fullName: "Abdylla Haljanov",
    email: "director@hectm.com",
    name: "Abdylla",
    image: team1,
    job: "Managing Director",
    shortDescription:
      "Managing Director responsible for the overall strategy and success of the team.",
    mainText:
      "Abdylla Haljanov is the Managing Director and Founder of Bilimli Adim, a higher educational consulting company that helps students achieve their academic goals and reach their full potential. He has over 20 years of experience in the education industry, and he has helped hundreds of students get into their dream schools and universities.",
    informations: [
      {
        id: "k82jjjuid83hf",
        type: "text",
        text: "Abdylla Haljanov is the Managing Director and Founder of Bilimli Adim, a higher educational consulting company that helps students achieve their academic goals and reach their full potential. He has over 20 years of experience in the education industry, and he has helped hundreds of students get into their dream schools and universities.",
      },
      {
        id: "309t4uhgjw03987r",
        type: "list",
        text: "He is also an expert in the following areas:",
        list: [
          "Higher education systems",
          "International student admissions process",
          "STEM education",
          "Financial aid for international students",
        ],
      },
      {
        id: "2309rufjw038h",
        type: "text",
        text: "Abdylla is committed to providing his students with personalized and comprehensive counseling services. He works with each student to develop a customized plan that meets their individual needs and goals. He also provides his students with the resources and support they need to succeed.",
      },
      {
        id: "392uf2083f208t3",
        type: "text",
        text: "Abdylla is a highly motivated and experienced educational consultant with a proven track record of success. He is passionate about helping students achieve their academic goals and reach their full potential.",
      },
      {
        id: "nf93[oor93ghd92j03oc",
        type: "list",
        text: "Here are some of Abdylla's notable achievements:",
        list: [
          "Helped over students get into their dream schools and universities.",
          "Secured scholarships and financial aid for his students.",
          "Increased the average SAT score of his students by over 100 points.",
          "Helped his students get into top universities around the world, including the UK, Turkiye, Indonesia, China, and Russia.",
        ],
      },
      {
        id: "fj9hf0fd93fj0",
        type: "text",
        text: "Abdylla is a highly respected member of the education community. He is a frequent speaker at educational conferences and events, and he has been featured in articles and interviews in major publications",
      },
      {
        id: "jyfpw039ujwoi3s4g9",
        type: "text",
        text: "If you are a student who is looking for help with college and university admissions, scholarships and financial aid, or test preparation, I encourage you to contact Abdylla today. He would be happy to discuss your needs and help you develop a plan to achieve your academic goals.",
      },
      {
        id: "hyj390fijwo983g9fj",
        type: "text",
        text: "Contact Abdylla today to learn more about how he can help you achieve your academic goals!",
      },
    ],
  },
  "gulnara-hanova": {
    fullName: "Gulnara Hanova",
    name: "Gulnara",
    email: "sr.consultant1@hectm.com",
    image: team2,
    title:
      "Gulnara Hanova is a Senior Consultant on the team, with over 4 years of experience in the industry. She is an expert in a variety of fields, including human resources, organizational development, and talent management. She is a highly motivated and results-oriented consultant, and is always looking for ways to exceed her clients' expectations. She is known for her strong interpersonal skills, ability to build relationships, and commitment to her clients' success. She is a valuable asset to the team and is highly respected by her clients and colleagues.",
    job: "Senior Consultant",
    shortDescription:
      "Highly motivated and results-oriented consultant, always looking for ways to exceed clients' expectations.",
    mainText:
      "Gulnara Hanova is an Educational Consultant at Bilimli Adim. She has helped dozens of students get into their dream schools and universities.",
    informations: [
      {
        id: "92djc0923hf0923ih",
        type: "text",
        text: "Gulnara specializes in helping students with college and university admissions counseling, scholarship and financial aid counseling, and essay writing and editing. She is also an expert in the higher education system.",
      },
      {
        id: "ntfhji87gjt54578jgf7uo908yg",
        type: "text",
        text: "Gulnara is known for her organized and efficient approach to counseling. She is always able to help her students stay on track and meet deadlines. Gulnara is also known for her expertise in essay writing. She has helped many students to write strong and compelling personal statements and supplemental essays.",
      },
      {
        id: "jkgyu6gj875fhko976rvhj",
        type: "text",
        text: "Gulnara Hanova is the perfect consultant for students who are interested in studying in the Russia. She has a deep understanding of the Russian higher education system and can help you navigate the admissions process with ease. Gulnara is also an expert essay writer and can help you craft a personal statement that will make you stand out from other applicants.",
      },
      {
        id: "jf83jdlc9shje9fkd4gd",
        type: "list",
        text: "She is a valuable asset to any student's college application team.",
        list: [
          "Expert in the Russian higher education system",
          "Passionate about helping students achieve their dreams",
          "Organized and efficient",
          "Patient and supportive",
          "Skilled in essay writing and editing",
        ],
      },
    ],
  },
  "dayanch-gurbangeldiyev": {
    fullName: "Dayanch Gurbangeldiyev",
    name: "Dayanch",
    email: "dayanch.g@hectm.com",
    image: team4,
    job: "Senior Consultant",
    shortDescription:
      "Senior Consultant with over 7 years of experience in the industry.",
    mainText:
      "Dayanch Gurbangeldiyev is a Senior Educational Consultant at Bilimli Adim, with over 13 years of experience in the education industry. He has helped dozens of students get into their dream schools and universities.",
    informations: [
      {
        id: "jf2fojs3r0jf3fdh4hhfd",
        type: "text",
        text: "Dayanch Gurbangeldiyev is a Senior Consultant at Bilimli Adim with experience in the higher educational consulting industry. He specializes in helping students with college and university admissions counseling, scholarship and financial aid counseling, and test preparation. He is also an expert in the European higher education system and the international student admissions process.",
      },
      {
        id: "jt0ot4fj3r09fjs30oev",
        type: "text",
        text: "Dayanch is known for his comprehensive and personalized approach to counseling. He takes the time to understand each student's individual needs and goals, and he develops a customized plan to help them achieve them. Dayanch is also known for his supportive and encouraging demeanor. He is always there for his students, and he helps them to stay motivated and on track throughout the admissions process.",
      },
      {
        id: "fji309ifj30w3fjw039ihf",
        type: "text",
        text: "Dayanch Gurbangeldiyev is the perfect consultant for students who are looking for a comprehensive and personalized approach to college admissions counseling. Dayanch has a deep understanding of the higher education systems of Europe and the international student admissions process, and he can help you navigate the process with ease. Dayanch is also a great supporter and will help you stay motivated throughout the admissions process.",
      },
      {
        id: "fji309ifj30w3fjw039ihf",
        type: "text",
        text: "",
        list: [
          "Expert in college and university admissions counseling, scholarship and financial aid counseling, and test preparation",
          "Proven track record of success in helping students get into their dream schools",
          "Comprehensive and personalized approach to counseling",
          "Supportive and encouraging demeanor",
          "Frequent speaker at educational conferences and events",
        ],
      },
    ],
  },
  "ahmet-hojamberdiyev": {
    fullName: "Ahmet Hojamberdiyev",
    name: "Ahmet",
    email: "cordinator@hectm.com",
    image: team5,
    job: "Senior Consultant",
    title:
      "Ahmet Hojamberdiyev is a Senior Consultant on the team, with over 6 years of experience in the industry. He is a highly skilled and experienced consultant, with expertise in a variety of fields, including business process reengineering, change management, and project management. He is always looking for new ways to improve the team's services, and is known for his critical thinking skills, ability to think outside the box, and commitment to excellence. He is a valuable asset to the team and is instrumental in helping the team achieve its goals.",
    shortDescription:
      "Senior Consultant with over 6 years of experience in the industry.",
    mainText:
      "Ahmet Hojamberdiyev is an Educational Consultant at Bilimli Adim with over 11 years of experience in the education industry. He has helped dozens of students get into their dream schools and universities.",
    informations: [
      {
        id: "jufdj398dfv398idi30f9gbfie",
        type: "text",
        text: "Ahmet Hojamberdiyev is a Senior Consultant at Bilimli Adim with experience in the higher educational consulting industry and sport academies. He specializes in helping students with test preparation and interview preparation. He is also an expert in the international student admissions process.",
      },
      {
        id: "o3uf93wojf9020i2djcv98w2o3ufv8",
        type: "text",
        text: "Ahmet is known for his energetic and enthusiastic personality. He is always able to motivate his students and help them to stay focused on their goals. Ahmet is also known for his expertise in test preparation. He has helped many students to improve their scores on standardized tests such as the SAT, ACT, and TOEFL.",
      },

      {
        id: "jfi9039en3je9ci3hif3jj9dhg98i",
        type: "text",
        text: "Ahmet Hojamberdiyev is the perfect consultant for students who are struggling with test preparation or interview preparation. He is an expert in both areas and can help you develop a personalized plan to achieve your goals. Ahmet is also a great motivator and can help you stay on track throughout the admissions process. He is a valuable asset to any student's college application team",
      },
      {
        id: "f9jd82bkihs93hkmbdjs983h",
        type: "list",
        text: "",
        list: [
          "Expert in test preparation and interview preparation",
          "Energetic and enthusiastic",
          "Expert in sport academies",
          "Ability to motivate students",
          "Expertise in the international student admissions process",
          "Strong communication and interpersonal skills",
        ],
      },
    ],
  },
  "ahmet-tashliyev": {
    fullName: "Ahmet Tashliyev",
    name: "Ahmet",
    image: team6,
    email: "sr.consultant2@hectm.com",
    job: "Senior Consultant",
    title:
      "Ahmet Tashliyev is a Senior Consultant on the team, with over 5 years of experience in the industry. He is a valuable asset to the team, with expertise in a variety of fields, including data analysis, machine learning, and artificial intelligence. He is always willing to share his knowledge and expertise with his colleagues, and is known for his collaborative spirit, willingness to help others, and dedication to the team's success. He is a valuable asset to the team and is highly respected by his colleagues.",
    shortDescription:
      "Senior Consultant with over 5 years of experience in the industry.",
    mainText:
      "Ahmet Tashliyev is an Educational Consultant at Bilimli Adim with over 8 years of experience in the education industry. He has helped dozens of students get into their dream schools and universities.",
    informations: [
      {
        id: "oeik3mo4aviwe093hf",
        type: "text",
        text: "Ahmet Tashliyev is a Senior Consultant at Bilimli Adim with experience in the higher educational consulting industry. He specializes in helping students with college and university admissions counseling, scholarship and financial aid counseling, and essay writing and editing. He is also an expert in the STEM field and financial aid for international students.",
      },
      {
        id: "39fj0w39hf0-938f",
        type: "text",
        text: "Ahmet is known for his expertise in the STEM field and his ability to help students write strong and compelling college essays. He is also known for his patient and supportive approach to counseling. Ahmet takes the time to get to know each student's individual interests and goals, and he helps them to develop a plan to achieve them.",
      },
      {
        id: "nf9oke3nf9p3jw93rfg4h",
        type: "text",
        text: "Ahmet Tashliyev is the perfect consultant for students who are interested in studying in Asia especially in Indonesia and Malaysia. He has a deep understanding of the field and can help you navigate the admissions process with ease. Ahmet is also a great essay writer and can help you craft a personal statement that will make you stand out from other applicants.",
      },
      {
        id: "nod892nklfgsi30gujs4r",
        type: "list",
        text: "",
        list: [
          "Expert in college and university admissions counseling, scholarship and financial aid counseling, and essay writing and editing",
          "Expertise in the field and financial aid for international students",
          "Ability to help students write strong and compelling college essays",
          "Patient and supportive approach to counseling",
        ],
      },
    ],
  },
  "rejepguly-hallyguliyev": {
    fullName: "Rejepguly Hallyguliyev",
    name: "Rejepguly",
    email: "rejep.h@hectm.com",
    image: team3,
    job: "Marketing Manager",
    title:
      "Rejepguly Hallyguliyev is the Marketing Manager of the team, responsible for developing and executing the team's marketing strategy. He is a passionate advocate for the team and its services, and has over 5 years of experience in marketing. He is always looking for new ways to promote the team and its services to potential clients, and is known for his creativity, innovation, and strong analytical skills. He is a valuable asset to the team and is instrumental in helping the team achieve its goals.",
    shortDescription:
      "Marketing Manager responsible for developing and executing the team's marketing strategy.",
    mainText:
      "Rejepguly Hallyguliyev is the Marketing Manager of the team. He has over 5 years of experience in marketing, and he is a passionate advocate for the team and its services. He is responsible for developing and executing the team's marketing strategy, and he is always looking for new ways to promote the team and its services to potential clients.",
    informations: [
      {
        id: "ofiu320fjpwi3fj",
        type: "text",
        text: "Rejepguly Hallyguliyev is the Marketing Manager of Bilimli Adim, a higher educational consulting company that helps students achieve their academic goals and reach their full potential. He has over 5 years of experience in the marketing industry, and he has a proven track record of success in developing and executing marketing campaigns that drive results.",
      },
      {
        id: "kihjd9ejdouyemrfo9dh",
        type: "text",
        text: "At Bilimli Adim, Rejepguly is responsible for developing and executing the company's marketing strategy. He oversees all aspects of marketing, including branding, advertising, social media, and public relations. He also works closely with the sales team to generate leads and close deals.",
      },
      {
        id: "fu9er8gjer8gheoir",
        type: "text",
        text: "Rejepguly is passionate about helping students achieve their dreams. He is committed to developing marketing campaigns that resonate with students and encourage them to consider Bilimli Adim for their educational consulting needs.",
      },
      {
        id: "ldnov83mou3nc9v8wh",
        type: "list",
        text: "Here are some of Rejepguly's notable achievements:",
        list: [
          "Increased brand awareness for Bilimli Adim",
          "Generated leads for the team",
          "Increased website traffic",
          "Increased social media engagement",
          "Secured placement for Bilimli Adim in major media outlets",
        ],
      },
      {
        id: "dhf0e0393oc9yh3",
        type: "text",
        text: "Rejepguly is a highly motivated and results-oriented marketing professional. He is known for his strategic thinking, his creativity, and his ability to execute complex projects. He is also a team player and is always willing to go the extra mile to help his colleagues.",
      },
      {
        id: "fj930kd0239hf029dh",
        type: "text",
        text: "Rejepguly is a highly motivated and results-oriented marketing professional. He is known for his strategic thinking, his creativity, and his ability to execute complex projects. He is also a team player and is always willing to go the extra mile to help his colleagues.",
      },
      {
        id: "bvobz983bghs74bg",
        type: "text",
        text: "Rejepguly is a highly motivated and results-oriented marketing professional. He is known for his strategic thinking, his creativity, and his ability to execute complex projects. He is also a team player and is always willing to go the extra mile to help his colleagues.",
      },
      {
        id: "l9jgy7trfgjht67uh",
        type: "list",
        text: "Here is a more detailed description of Rejepguly's role as the Marketing Manager of Bilimli Adim:",
        list: [
          "Branding: Rejepguly is responsible for developing and maintaining Bilimli Adim's brand identity. This includes creating and managing the company's logo, tagline, and overall messaging. He also works to ensure that all of Bilimli Adim's marketing materials are consistent with the company's brand identity.",
          "Advertising: Rejepguly develops and executes Bilimli Adim's advertising campaigns. This includes working with advertising agencies to create and place ads in print, online, and on television. He also works to track the results of Bilimli Adim's advertising campaigns and make adjustments as needed.",
          "Social media: Rejepguly manages Bilimli Adim's social media presence. This includes creating and posting content on Facebook, Twitter, Instagram, and LinkedIn. He also works to engage with Bilimli Adim's followers on social media and answer their questions.",
          "Public relations: Rejepguly pitches stories about Bilimli Adim to journalists and other media outlets. He also works to build relationships with key influencers in the education industry.",
          "Lead generation: Rejepguly works with the sales team to generate leads for Bilimli Adim. This includes developing and executing lead generation campaigns, such as webinars, e-books, and white papers.",
        ],
      },
      {
        id: "jiug8jf6iofr46jy7",
        type: "text",
        text: "Rejepguly is a valuable asset to the Bilimli Adim team. He is a highly skilled and experienced marketing professional who is passionate about helping students achieve their dreams.",
      },
    ],
  },
};

export const testimonials = [
  {
    person: "Gulperi Etrekova",
    comment:
      "I was overwhelmed with the admissions process, Bilimli Adim helped me every step of the way. My consultant, Dayanch Gurbangeldiyev, was supportive and always available to answer my questions. He helped me develop a strong college list and prepare for my interviews. ",

    university: "Ataturk University, Turkiye",
    img: testimonial1,
  },
  {
    person: "Aygozel Dowletova",
    comment:
      "Bilimli Adim helped me improve my score for my test! They helped me develop a personalized study plan that worked for me. I'm so grateful for their help, and I highly recommend their test preparation services to any student who is looking to improve their score.",

    university: "Northwest University of China.",
    img: testimonial2,
  },
  {
    person: "Tazegul Niyazmammedova",
    comment:
      "I was set on studying in Indonesia, but I didn't know where to start. Ahmet Tashliyev at Bilimli Adim helped me find the perfect university for my needs and goals. I highly recommend his services to any student who is interested in studying in Indonesia.",

    university: "Universitas Sebelas Maret, Indonesia",
    img: testimonial3,
  },
];
