import { Col, Row } from "antd";
import React from "react";
import service from "../../images/service1.jpg";
import service2 from "../../images/service2.jpg";
import service3 from "../../images/service3.jpg";
import "./Service.css";

function Service() {
  return (
    <div className="services">
      <div className="main-title-container">
        <h1 className="main-color">SERVICES</h1>
      </div>
      <div className=" ">
        <Row className="services__section">
          <Col
            className="d-flex justify-content-center align-items-center mt-3"
            md={{
              span: 12,
            }}
            span={24}
          >
            <p className="fs-4 px-3 text-justify">
              Our mission is to place genuine education minded students at
              genuine education providers. We aim to take leadership in the
              student consultancy industry and set examples for others with our
              distinguished quality of services for students and education
              providers. We strive to uphold the excellence of higher education
              by providing inclusive, competent and professional supports to
              student community and educational institutions.
            </p>
          </Col>
          <Col
            className="d-flex justify-content-center align-items-center mt-3"
            md={{
              span: 12,
            }}
            span={24}
          >
            <img
              className="img-fluid px-4 "
              src={service}
              alt="This is service image"
            />
          </Col>
        </Row>
        <Row className="services__section">
          <Col
            className="d-flex justify-content-center align-items-center mt-3"
            md={{
              span: 12,
              order: 0,
            }}
            order={1}
            span={24}
          >
            <img
              className="img-fluid px-4"
              src={service2}
              alt="This is service image"
            />
          </Col>
          <Col
            className="d-flex justify-content-center align-items-center mt-3"
            md={{
              span: 12,
            }}
            span={24}
          >
            <div>
              <p className="fs-4 text-justify px-3">
                To successfully place students for the course at a institution
                only that are in the best interest of the student or their
                proﬁle.
              </p>
              <p className="fs-4 text-justify px-3">
                To promote all round development of students abilities and
                personalities, and empower the potential. To provide effortless
                and distinctive quality service and commit to improve
                continually.
              </p>
            </div>
          </Col>
        </Row>
        {/* <div className="text-bg-danger wider-div">Hello</div> */}
        <Row className="services__section">
          <Col
            className="d-flex justify-content-center align-items-center mt-3"
            md={{
              span: 12,
            }}
            span={24}
          >
            <p className="fs-4 text-justify px-3">
              We believe that education is a fundamental right and everyone
              should have access to quality higher education. With this view in
              mind, we strive to create opportunities for those who have genuine
              aspiration and honest intention, who seek excellent quality in
              tuition, student services, qualiﬁcations and career prospects post
              qualiﬁcation.{" "}
            </p>
          </Col>
          <Col
            className="d-flex justify-content-center align-items-center mt-3"
            md={{
              span: 12,
            }}
            span={24}
          >
            <img
              className="img-fluid px-4 "
              src={service3}
              alt="This is service image"
            />
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default Service;
