import { Col, Row } from "antd";
import React from "react";
// import education from "../../images/3721106.jpg";
// import education2 from "../../images/edu.jpg";
import about1 from "../../images/about/about1.jpg";
import about2 from "../../images/about/about2.jpg";
import education from "../../images/education.webp";
import education2 from "../../images/education2.jpg";
import Testimonial from "./Testimonial.jsx";

import { testimonials } from "../../utils/database";
import "./About.css";

function About() {
  return (
    <div className="about">
      <div className="main-title-container">
        <h1 className="main-color text-uppercase">About BILIMLI ADIM</h1>
      </div>
      <div>
        <Row className="my-3">
          <Col
            className="d-flex justify-content-center align-items-center mb-5 pb-5"
            lg={{
              span: 12,
              order: 0
            }}
            order={1}
            span={24}
          >
            <p className="fs-5 px-3 about_text pt-3">
              <span className="main-color">BILIMLI ADIM</span> is an Agency who
              hold a wealth of experience within the Education Sector. Our
              passion and personal approach sets us aside and makes us a unique
              Agency. We work closely with our schools and universities, to
              understand their requirements and to match the right candidate to
              the right role. We have an expert team of academic consultants. At{" "}
              <span className="main-color">BILIMLI ADIM</span> we understand how
              important it is to start a new academic and cultural journey.
              Therefore services tailored to meet speciﬁc needs of our students
              before and after their arrival to the university including
              introduction to lifestyle, cultural awareness, public transport,
              banks, location of university, translation and interpretation
              services, personal counselling and personal assistance.
            </p>
          </Col>
          <Col
            className="d-flex justify-content-center align-items-start"
            lg={{
              span: 12,
            }}
            span={24}
          >
            <img
              className="img-fluid px-4 "
              src={education}
              alt="This is education image"
            />
          </Col>
        </Row>
        <Row className="my-3 mb-5 pb-5">
          <Col
            className="d-flex justify-content-center align-items-center"
            lg={{
              span: 12,
              // order: 0,
            }}
            // order={1}
            span={24}
          >
            <img
              className="img-fluid px-4"
              src={education2}
              alt="This is education image"
            />
          </Col>
          <Col
            className="d-flex flex-column justify-content-center align-items-center "
            lg={{
              span: 12,
            }}
            span={24}
          >
            <p className="fs-5 px-3 about_text pt-3">
              Working across Turkmenistan as a single united team, we’re the
              only company with the range of expertise to deliver end-to-end
              innovation. We accelerate new growth ideas from concept, through
              design and development to commercial success. And we revitalise
              organisations with the leadership, culture, systems and processes
              to make innovation a reality.
            </p>
            <p className="fs-5 px-3 about_text">
              We deliver great work because of our brilliant people who live our
              purpose every day.
            </p>
            <p className="fs-5 px-3 about_text">
              We believe in the power of ingenuity to build a positive human
              future.
            </p>
          </Col>
        </Row>
        <Row className="my-3 mb-5 pb-5">
          <Col
            className="d-flex justify-content-center align-items-center"
            lg={{
              span: 12,
              order: 1,
            }}
            order={0}
            span={24}
          >
            <img
              className="img-fluid px-4"
              src={about1}
              alt="This is education image"
            />
          </Col>
          <Col
            className="d-flex flex-column justify-content-center align-items-center "
            lg={{
              span: 12,
            }}
            span={24}
          >
            <p className="fs-5 px-3 about_text pt-3">
              This is{" "}
              <span className="main-color text-uppercase">Bilimli Adim</span>
              ’s purpose, the lighthouse guiding what we do, how we do it and,
              most importantly, why we do it. It means we’re proud to work with
              clients who make a positive contribution on ground-breaking and
              innovative projects that improve people’s lives.
            </p>
            <p className="fs-5 px-3 about_text">
              For us, ingenuity is a mindset. We’re optimists who believe that
              when you put bright people and brilliant technology together,
              amazing things happen. It isn’t just transformative and
              fascinating. It means delivering actual results every day, helping
              find new opportunities for our clients, communities, and the wider
              world.
            </p>
          </Col>
        </Row>
        <Row className="my-3 mb-5 pb-5">
          <Col
            className="d-flex justify-content-center align-items-center"
            lg={{
              span: 12,
              order: 0,
            }}
            order={0}
            span={24}
          >
            <img
              className="img-fluid px-4"
              src={about2}
              alt="This is education image"
            />
          </Col>
          <Col
            className="d-flex flex-column justify-content-center align-items-center "
            lg={{
              span: 12,
            }}
            span={24}
          >
            <p className="fs-5 px-3 about_text pt-3">
              It’s this mindset that creates the environment for progress. And
              with this at the heart of who we are and what we do, we believe
              that a positive human future is perfectly achievable.
            </p>
            <p className="fs-5 px-3 about_text">
              Educational institutions have shown they are able to adapt at pace
              and respond to change. But an increasingly competitive market and
              the readiness with which students have adapted to online and
              blended learning show that institutions need to carve at a new
              role.
            </p>
          </Col>
        </Row>
      </div>
      <div>
        <p className="fs-5 px-3 about_text">
          At <span className="main-color text-uppercase">Bilimli Adim</span>, we
          can make you thrive in changing world - by driving transformation,
          reducing costs and giving students, academics, and professionals
          service staff the highest quality service.
        </p>
        <p className="fs-5 px-3 about_text">
          The path forward is rarely well-paved. Time-tested practices may guide
          the journey, but they can only map the ground they have covered.
          Moving forward requires fresh thinking, the courage to venture beyond,
          and a capable partner to guide you on the way.
        </p>
      </div>
      <div className="pt-5">
        <h1 className="main-color my-4 mt-5">Testimonials</h1>
        {testimonials.map(({ person, comment, university, img }) => (
          // <SwiperSlide key={person}>
          <Testimonial
            key={person}
            person={person}
            comment={comment}
            university={university}
            img={img}
          />
          // </SwiperSlide>
        ))}
      </div>
    </div>
  );
}

export default About;
