import React from "react";
import "./Testimonial.css";

const Testimonial = ({ person, comment, university, img }) => {
  return (
    <div className="testimonial flex-sm-row flex-column my-5 border shadow rounded-4 py-4 p-sm-4 p-0 text-start">
      <div className="testimonial__img-container pb-2">
        <img src={img} alt="Ahmet" />
      </div>
      <div className="px-3 w-100">
        <h4 className="p-0 fw-bold">
          <span>{person}</span>
        </h4>
        <h6 className="text-truncate py-2 text-white">
          <span className="bg-main px-2 rounded ">
            {university}
          </span>
        </h6>
        <p className="text-justify">"{comment}"</p>
      </div>
    </div>
  );
};

export default Testimonial;
