import React from "react";
import {
  AiFillPhone,
  AiFillYoutube,
  AiOutlineInstagram,
  AiOutlineMail,
  AiOutlineTeam,
} from "react-icons/ai";
import { FaTiktok } from "react-icons/fa";
import { FcAbout } from "react-icons/fc";
import { RiCustomerService2Fill } from "react-icons/ri";
import { Link } from "react-router-dom";
import "./Footer.css";

const Footer = () => {
  return (
    <div className="border-top mt-5">
      <div className="container-xxl mt-3 py-2 bg-white footer">
        <div className="footer-content d-flex flex-wrap pt-3">
          <div className="footer-column d-flex justify-content-start mt-3 mt-md-0 col-7 col-sm-4 ">
            {/* <h3 className="footer-heading">About Us</h3> */}
            <ul className="footer-list text-start">
              <li>
                <Link
                  onClick={() => window.scrollTo(0, 0)}
                  className="footer__link"
                  to="/about-us"
                >
                  <FcAbout className="me-2 main-color" />
                  About
                </Link>
              </li>
              <li>
                <Link
                  onClick={() => window.scrollTo(0, 0)}
                  className="footer__link"
                  to="/service"
                >
                  <RiCustomerService2Fill className="me-2 main-color" />
                  Our Services
                </Link>
              </li>
              <li>
                <Link
                  onClick={() => window.scrollTo(0, 0)}
                  className="footer__link"
                  to="/team"
                >
                  <AiOutlineTeam className="me-2 main-color text-gradient" />
                  Our Team
                </Link>
              </li>
            </ul>
          </div>
          <div className="footer-column d-flex justify-content-md-center justify-content-start mt-3 mt-md-0 col-5 col-sm-4">
            {/* <h3 className="footer-heading">Social Media</h3> */}
            <ul className="footer-list text-start d-flex flex-column  justify-content-start">
              <li className="d-flex mt-1">
                <Link
                  onClick={() => window.scrollTo(0, 0)}
                  className="main-btn text-truncate"
                  to="/contact"
                >
                  Contact Us
                </Link>
              </li>
              <li className="d-flex justify-content-between">
                <a
                  target="_blank"
                  className="footer__link"
                  href="https://www.instagram.com/bilimli_adim"
                >
                  <AiOutlineInstagram
                    className="me-2 rounded bg-gradient text-bg-danger"
                    size={27}
                  />
                </a>
                <a
                  target="_blank"
                  className="footer__link"
                  href="https://www.youtube.com/bilimli_adim"
                >
                  <AiFillYoutube size={30} className="me-2 text-danger" />
                </a>
                <a
                  target="_blank"
                  className="footer__link"
                  href="https://www.tiktok.com/bilimli_adim"
                >
                  <FaTiktok size={25} className=" text-bg-dark rounded p-1" />
                </a>
              </li>
            </ul>
          </div>
          <div className="footer-column d-flex justify-content-start justify-content-md-end mt-3 mt-md-0 col-12 pe-3 col-sm-4">
            {/* <h3 className="footer-heading">Contact Us</h3> */}
            <ul className="footer-list text-start">
              <li>
                <a className="footer__link" href="mailto:info@hectm.com">
                  <AiOutlineMail className="me-2 main-color" />
                  info@hectm.com
                </a>
              </li>
              <li>
                <a
                  className="footer__link"
                  href="mailto:bilimli.adim@gmail.com"
                >
                  <AiOutlineMail className="me-2 main-color" />
                  bilimli.adim@gmail.com
                </a>
              </li>

              <li>
                <a className="footer__link" href="tel:+99361869151">
                  <AiFillPhone className="me-2 main-color" />
                  +993 61 86 91 51
                </a>
              </li>
            </ul>
          </div>
        </div>
        {/* </div> */}
        <div className="footer-copyright">
          <p className="fw-bold">
            Copyright © 2023 BILIMLI ADIM. All rights reserved.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
