import React, { useEffect, useState } from "react";
import { GiHamburgerMenu } from "react-icons/gi";
import { Link, useLocation } from "react-router-dom";
import logo from "../../images/logo.png";
import "./Nav.css";

function Nav({ showDrawer }) {
  const navLinks = [
    { link: "/", title: "Home" },
    { link: "/about-us", title: "About" },
    { link: "/team", title: "Team" },
    { link: "/service", title: "Services" },
    { link: "/contact", title: "Contact" },
  ];

  const [active, setActive] = useState("/");
  const { pathname } = useLocation();

  useEffect(() => {
    setActive(pathname);
  }, [pathname]);

  return (
    <div className="nav_container bg-main w-100 border-bottom">
      <div className="container-xxl px-0 px-md-2">
        <div className="d-flex justify-content-between align-items-center p-2 bg-opacity-50">
          <div className="text-left">
            <Link
              onClick={() => {
                window.scrollTo(0, 0);
                setActive("/");
              }}
              className="px-3 py-2 text-decoration-none"
              to="/"
            >
              <img
                style={{
                  maxWidth: 125,
                }}
                className="img-fluid my-2"
                alt="logo"
                src={logo}
              />
            </Link>
          </div>

          <GiHamburgerMenu
            onClick={showDrawer}
            size={30}
            className="main-color cursor-pointer d-flex d-md-none me-2"
          />

          <ul className="d-none list-unstyled m-1 d-md-flex">
            {navLinks.map(({ link, title }) => {
              return (
                <li key={link} className={`m-0`}>
                  <Link
                    onClick={() => window.scrollTo(0, 0)}
                    className={`nav_link mx-1 px-3 py-2  text-decoration-none ${
                      active === link ? "nav_active-link" : ""
                    }`}
                    to={link}
                  >
                    {title}
                  </Link>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Nav;
