import "bootstrap/dist/css/bootstrap.css";
import { useState } from "react";
import "./App.css";
import MyDrower from "./components/MyDrower";
import Footer from "./components/footer/Footer";
import Layout from "./components/layout/Layout";
import Nav from "./components/navbar/Nav";

function App() {
  const [open, setOpen] = useState(false);
  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
    window.scrollTo(0, 0);
  };

  return (
    <div className="App">
      <MyDrower showDrawer={showDrawer} onClose={onClose} open={open} />
      <Nav showDrawer={showDrawer} />
      <Layout />
      <Footer />
    </div>
  );
}

export default App;
