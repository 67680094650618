import React from "react";
import { Link } from "react-router-dom";
import { teamData } from "../../utils/database";
import "./Team.css";

const Team = () => {
  return (
    <div className="team-container mx-auto">
      <div className="main-title-container">
        <h1 className="main-color my-5">Our Team</h1>
      </div>
      <div className="row team-card-container">
        {teamData.map(({ id, fullName, image, job, shortDescription }) => {
          return (
            <div key={id} className="team-card">
              <Link
                onClick={() => window.scrollTo(0, 0)}
                to={"/team/" + id}
                className="team-img-container text-dark border  w-100"
              >
                <img src={image} alt="Team Member" />
                <div className="team-title-container w-100">
                  <div className="team-title ps-3">
                    <h5 className="fw-bold">{fullName}</h5>
                    <h6 className="fw-bold fst-italic mb-2">{job}</h6>
                    <p
                      style={{
                        minHeight: 55,
                      }}
                      className=" px-4"
                    >
                      {shortDescription}
                    </p>
                  </div>
                </div>
              </Link>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Team;
